import React from 'react'

CityLabel.defaultProps = {
  classname: 'category',
  prefix: '',
  type: 'list'
}

function CityLabel({city, classname, prefix, ...props}) {

  const cityName = city?.name
      ? city?.name
      : city?.title
        ? city.title
        : null

  const cityLabel = cityName
      ? ` - ${cityName}`
      : ''

  return (
    <span className={classname}>
      {prefix}
      {cityLabel}
    </span>
  )
}

export default CityLabel
