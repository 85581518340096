import React, { lazy, Suspense, useRef, useEffect, useState } from "react"
import { useStaticQuery, graphql } from "gatsby"

import {
  filterStore,
  locationStore,
  pageStore
} from "state/store-zustand";


import CardSkeletons from "components/post/singCard/cardSkeletons"
import PlaceholderImage from "../../images/no_image_placeholder.png"

import PostSingCard from 'components/post/singCard/postCard'

const Filters = lazy(() => import('components/filters/Filters'))

import "./postIndex.scss"

const NeighborhoodPosts = ({ ...props }) => {
  const wpData = useStaticQuery(graphql`
    {
      allWpNeighborhood(filter: {}) {
        edges {
          node {
            content
            excerpt
            id
            postDetails {
              city {
                ... on WpCity {
                  id
                  slug
                  title
                }
              }
              vibeset {
                ... on WpVibeset {
                  id
                  slug
                }
              }
            }
            slug
          }
        }
      }
      allWpVibeset(sort: { order: ASC, fields: title }) {
        nodes {
          databaseId
          title
          slug
        }
      }
      allWpCategory {
        nodes {
          name
          databaseId
          slug
        }
      }
      wp {
        allSettings {
          readingSettingsPostsPerPage
        }
      }
      currentBuildDate {
        currentDate
      }
    }
  `)

  let filterContainer = useRef()
  let postContainer = useRef()

  const postsPerPage = wpData.wp.allSettings.readingSettingsPostsPerPage || 12

  const buildTime =
    process.env.GATSBY_ENV == "production"
      ? wpData.currentBuildDate.currentDate
      : new Date().toISOString()

  const [isLoading, setIsLoading] = useState(false)

  // TODO: Use memo? And how to keep both the full and filtered set
  const allPosts = props.posts
  const [posts, setPosts] = useState(props.posts)
  const [page, setPage] = useState(1)
  const [totalPages, setTotalPages] = useState(1)


  const filters = filterStore(state => state.filters)
  const hasFilters = filterStore(state => state.hasFilters)
  const currentCity = locationStore(state => state.cityCurrent)
  //const getCity = locationStore(state => state.getCity)
  const isMobile = pageStore((state) => state.isMobile)

  /*
  TODO: Replace with smaller react-scroll library.
  const scrollTop = () => {
    gsap.to(window, 0.5, {
      scrollTo: {
        y: filterContainer.current,
        offsetY: 150,
      },
    })
  }
  */

  // TODO: Remove pagination
  const handlePrevPage = () => {
    setPage(page - 1 ? page - 1 : 1)
  }
  const handleNextPage = () => {
    setPage(page < totalPages ? page + 1 : totalPages)
  }

  const onChange = (newFilters) => {

    setCity(newFilters.cities[0])
    setFilters(newFilters)

    //console.log('Filter by this city ', cotu)
  }

  // TODO: Effect / event handled to filter by city
  useEffect(() => {
    const hasCityFilter = filters.cities != undefined && filters.cities.length > 0

    const city = hasCityFilter
      ? getCity(filters.cities[0])
      : currentCity

    // If a city is selected, filter by it.
    if (city) {
      const filteredPosts = allPosts.filter((post) => {
        // Matches post city or map city
        const postDetails = post.node.postDetails
        const postCities = postDetails && postDetails.city ? postDetails.city.map(city => city.slug) : []

        const matchesCity = postCities.includes(city.slug)

        const neighborhood = post.node.neighborhood
        const { map = null } = neighborhood
        const mapCity = map?.city ? map.city.toLowerCase().replace(' ', ' -') : null
        const matchesMap = mapCity && mapCity == city.slug

        if (matchesCity || matchesMap) {
          return post
        }
      })
      setPosts(filteredPosts)
    }
  }, [currentCity, filters])

  return (
    <section className="s-PostIndex">
      <div className="container">
        <Suspense fallback={<span>...</span>}>
          <Filters />
        </Suspense>

        <div ref={postContainer} id="post-container">
          {posts.length == 0 &&
            <div>There are currently no guides for this city.</div>
          }
          {isLoading === true
            ? <CardSkeletons num={isMobile ? 1 : 3} />
            : (
              <ul className="columns is-multiline list" id="post-list-container">
                {
                  posts.map((node, i) => {
                    const post = node.node
                    let image = PlaceholderImage
                    let city = ''

                    const photos = post.neighborhood && post.neighborhood.photos
                      ? post.neighborhood.photos
                      : null

                    if (photos && photos.length > 0) {
                      image = photos[0].localFile.publicURL
                    }

                    if (post.neighborhood.map && post.neighborhood.map.city) city = post.neighborhood.map.city

                    const mapCity = post.neighborhood.map && post.neighborhood.map.city ? post.neighborhood.map.city : null
                    // TODO: Check for map city or postDetails city tag
                    if (mapCity) {
                      city = mapCity
                    }

                    const category = post.categories && post.categories.length > 0 ? post.categories.nodes[0].neightborhood : null

                    return (
                      <li
                        key={`${i}_postCard`}
                        className="column is-4-desktop is-6-tablet is-12-mobile"
                      >
                        <PostSingCard
                          style="flat"
                          orientation="vertical"
                          url={post.uri.replace('/features/neighborhood/', '/neighborhoods/')}
                          title={post.title}
                          category={category ? category : 'Guide'}
                          city={city}
                          imageSrc={image}
                          contentToAnalyze={post.content}
                        />
                      </li>
                    )
                  })
                }
              </ul>
            )
          }

          <div className="pagination columns">
            <div className="column is-3 has-text-left">
              {page !== 1 ? (
                <button className={'button'} onClick={handlePrevPage}>Previous</button>
              ) : null}
            </div>
            <div className="column is-6 has-text-centered">
              {totalPages !== 0 ? (
                <p>{`Page ${page} of ${totalPages}`}</p>
              ) : (
                <p style={{ padding: `12rem 0` }}>
                  {`There are no features that match your selected criteria.`}
                </p>
              )}
            </div>
            <div className="column is-3 has-text-right">
              {page !== totalPages && totalPages !== 0 ? (
                <button className={'button'} onClick={handleNextPage}>Next</button>
              ) : null}
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

NeighborhoodPosts.defaultProps = {
  category: null,
  categoryId: null
};

export default NeighborhoodPosts
