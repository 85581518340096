import React from "react"

import useMobileDetect from 'use-mobile-detect-hook'
const detectMobile = useMobileDetect()

import "./appStoreCallout.scss"

const AppStoreBadge = ({ appStoreUrl, googlePlayUrl, ...props }) => {
  const isMobile = detectMobile.isMobile()
  const isIos = detectMobile.isIos()
  const isAndroid = detectMobile.isAndroid()

  const badgeWidth = isMobile
    ? (isIos || isAndroid) ? 200 : 140
    : 160
  const badgeHeight = isMobile
    ? (isIos || isAndroid) ? 60 : 50
    : 70

  const iOSOffset = 36;

  const AppleLink = (
    <a href={appStoreUrl} style={{ paddingTop: '10px' }}>
      <img
        loading='lazy'
        src="https://tools.applemediaservices.com/api/badges/download-on-the-app-store/black/en-us?"
        alt="Download Vibemap on the App Store"
        width={badgeWidth - iOSOffset}
      />
    </a>
  )

  const AndroidLink = (
    <a href={googlePlayUrl}>
      <img
        loading='lazy'
        src="https://play.google.com/intl/en_us/badges/static/images/badges/en_badge_web_generic.png"
        alt="Download Vibemap on Google Play"
        style={{ height: 'auto', minHeight: badgeHeight - 7 }}
        width={badgeWidth - 16}
      />
    </a>
  )

  if (isIos) {
    return <div className="app-store-links">
      {AppleLink}
    </div>
  }

  if (isAndroid) {
    return <div className="app-store-links">
      {AndroidLink}
    </div>
  }

  return (
    <ul className="app-store-links has-text-centered-mobile has-text-left-tablet">
      <li>
        {AppleLink}
      </li>
      <li>
        {AndroidLink}
      </li>
    </ul>
  )
}

AppStoreBadge.defaultProps = {
  appStoreUrl: 'https://apps.apple.com/us/app/vibemap/id1496385897#?platform=iphone',
  googlePlayUrl: 'https://play.google.com/store/apps/details?id=com.vibemap.hotspots'
}

export default AppStoreBadge
