import React, { useEffect, useState } from 'react'

import Skeleton from "react-loading-skeleton"
import { Saved } from "components/elements/saved"
import { useLocation } from '@reach/router'

function LazyBackground({
  src,
  className,
  children,
  place,
  handleSave,
  isVisible,
  isSaved,
  isShowSaved,
  isShow,
  style
}) {

  const timeout = 500
  const [source, setSource] = useState(null)
  const [showImage, setShowImage] = useState(false)
  const [imageLoaded, setImageLoaded] = useState(false)

  const onLoad = () => {
    try {
      if (imageLoaded == false) {
        setSource(src)
        setShowImage(true)
        setImageLoaded(true)
      }
    } catch (error) {
      console.log('DEBUG: error', error)
    }

  }

  // Check if loaded after timeout
  useEffect(() => {
    const timer = setTimeout(() => {
      if (imageLoaded == false) onLoad()
    }, timeout);
    return () => clearTimeout(timer)
  }, []);

  useEffect(() => {
    const img = new Image()
    img.src = src
    img.onload = () => onLoad()
  }, [src])

  const containerClassname = `background-image${className ? ' ' + className : ''}`
  const hasImage = typeof source !== 'undefined'
  const savedIcon = (
    <Saved
      place={place}
      handleClick={handleSave}
      isSaved={isSaved}
    />
  )

  const [imageStyle, setImageStyle] = useState({})

  useEffect(() => {
    const newStyle = hasImage
      ? {
        background: style?.backgroundColor,
        backgroundImage: source ? `url(${source})` : `none`,
        backgroundSize: `cover`,
        backgroundRepeat: `no-repeat`,
        backgroundPosition: `center`,
      }
      : style

    setImageStyle(newStyle)
  }, [source, style])

  const isReady = imageLoaded || isVisible || showImage
  return (
    <div className='sing-card-image'>
      {isReady
        ? (
          <div
            className={containerClassname}
            onLoad={onLoad}
            style={imageStyle}
          >
            {isShowSaved ? savedIcon : ''}

            {children}
          </div>
        )
        : (
          <div className={`background-image`} ></div>
        )
      }

    </div>

  )
}

export default LazyBackground
